import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Section } from '../common/section';

export const Details: React.FC = () => {
  const { i18n } = useTranslation();
  const [markdown, setMarkdown] = useState(undefined);

  useEffect(() => {
    async function fetchMarkdown() {
      const md = await import(`./details.${i18n.language}.md`);
      setMarkdown(md);
    }
    fetchMarkdown();
  }, [i18n.language]);

  return (
      <Section>
        {
          markdown &&
          <div dangerouslySetInnerHTML={{ __html: markdown.html }} />
        }
      </Section>
  );
};