import React from 'react';
import { SEO } from '../components/seo';
import { Details } from '../components/technology/details';
import { Introduction } from '../components/technology/introduction';
import { NormalLayout } from '../components/layouts/normal_layout';

const Vision: React.FC = () => {

  return (
      <NormalLayout>
        <SEO title="UVC Technology | UVX Solutions" description="UVC Technology | UVX Solutions" />
        <Introduction />
        <Details />
      </NormalLayout>
  );
};

export default Vision;